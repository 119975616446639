function fromToImg(from, parent = null) {
  let guidToImgUrl;
  // console.log('from', from, 'parent', parent);
  if (parent === 'Register') {
    guidToImgUrl = {
      //JGSoccer.com
      '660a1c8149a3f75c60b457e1':
        'https://res.cloudinary.com/joegalaxy/image/upload/v1721518015/Images/tomq4dq5uiglbr2tcgil.png',
      // Add more mappings specific to 'Register' as needed
      'la-joya-eco-community':
        'https://res.cloudinary.com/joegalaxy/image/upload/v1719531368/LaJoyaLogo_cbapwt.jpg',
      'camden-live-galaxy':
        'https://res.cloudinary.com/joegalaxy/image/upload/v1715229523/thielxhez0teywzmwycg_srujsi.jpg',
    };
  } else {
    guidToImgUrl = {
      'camden-live-galaxy':
        'https://res.cloudinary.com/joegalaxy/image/upload/v1715229523/thielxhez0teywzmwycg_srujsi.jpg',
      'la-joya-eco-community':
        'https://res.cloudinary.com/joegalaxy/image/upload/v1719531368/LaJoyaLogo_cbapwt.jpg',
      '660a1c8149a3f75c60b457e1':
        'https://res.cloudinary.com/joegalaxy/image/upload/v1721517692/pmfbyapwggeltiqytwz7_fdebdb.png',
      // Add more mappings as needed
    };
  }

  const guid = from?.slice(-24);

  // Check if any key is a substring of `from`
  for (const key in guidToImgUrl) {
    if (from?.includes(key)) {
      return guidToImgUrl[key];
    }
  }

  // Fallback to previous logic if no substring match is found
  return guidToImgUrl[from] || // Check the full input string
    guidToImgUrl[guid] || // Fallback to checking the GUID
    parent === 'Topbar'
    ? 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656195406/Images/JGNETLogo_zzxerg.png'
    : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658172769/Images/sbcgiu6mxdtvqvjflfwu.png'; // Default image
}
export default fromToImg;
