import { useEffect, useRef, useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useConfettiText } from '../../hooks/useConfettiText';
import Picker from 'emoji-picker-react';
import dataURItoBlob from '../../helpers/dataURItoBlob';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import axiosConn from '../../axiosConn';
import useClickOutside from '../../clickOutside';
import { useMediaQuery } from 'react-responsive';

import './createComment.css';
//import './style.css';

export default function CreateComment({
  memeId,
  postId,
  setComments,
  setCommentCount,
  parent,
}) {
  const { player } = useContext(AuthContext);
  const [picker, setPicker] = useState(false);
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [commentImage, setCommentImage] = useState('');
  const [cursorPosition, setCursorPosition] = useState();
  const [loading, setLoading] = useState(false);
  const textRef = useRef(null);
  const imgInput = useRef(null);
  const menu = useRef(null);
  // const commentInputRef = useRef(null); // Create a ref for the comment input

  // console.log(parent);
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  const [showConfettiText, ConfettiTextComponent] = useConfettiText(
    textRef,
    mobileView,
  );

  const handleClickOutside = () => {
    setPicker((prev) => !prev);
  };

  useClickOutside(menu, () => handleClickOutside());

  useEffect(() => {
    textRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  const handleEmoji = (e, { emoji }) => {
    const ref = textRef.current;
    ref.focus();
    const start = text.substring(0, ref.selectionStart);
    const end = text.substring(ref.selectionStart);
    const newText = start + emoji + end;
    setText(newText);
    setCursorPosition(start.length + emoji.length);
  };
  const handleImage = (e) => {
    let file = e.target.files[0];
    setFile(e.target.files[0]);
    if (
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/webp' &&
      file.type !== 'image/gif'
    ) {
      setError(`${file.name} format is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 10) {
      setError(`${file.name} is too large max 10mb allowed.`);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setCommentImage(event.target.result);
    };
  };
  const handleComment = async (e) => {
    // Prevent multiple submissions
    if (loading) return;
    //console.log('handleComment Top', text, e);
    // Trim the text to remove leading and trailing spaces
    const trimmedText = text.trim();
    //click is for mobile.
    if ((e.key === 'Enter' || e.type === 'click') && trimmedText !== '') {
      setLoading(true);
      if (picker) handleClickOutside();

      if (commentImage !== '') {
        setLoading(true);
        const img = dataURItoBlob(commentImage);
        //const path = `${player.playerId}/post_images/${postId}`;

        let formData = new FormData();
        const fileName = Date.now() + file.name;
        formData.append('name', fileName);
        //formData.append('path', path);
        formData.append('file', img);
        formData.append('upload_preset', 'cubejg');
        formData.append('cloud_name', 'joegalaxy');

        // const imgComment = await uploadImages(formData, path, player.token);

        try {
          let url = 'https://api.cloudinary.com/v1_1/joegalaxy/';
          // if (fileName.includes('.mp4')) {
          //   url = url + 'video/upload';
          // } else {
          url = url + 'image/upload';
          // }
          //console.log(url);
          await axios.post(url, formData).then(async (data) => {
            //console.log(data);
            const SecurePostImgQ_AutoUrl =
              data.data.secure_url.split('/upload/')[0] +
              '/upload/q_auto/' +
              data.data.secure_url.split('/upload/')[1];

            if (parent === 'Meme') {
              //Memes
              // console.log('parent === Meme');
              const comments = await axiosConn.put(
                '/memes/comment',
                {
                  comment: text,
                  memeId,

                  image: SecurePostImgQ_AutoUrl,
                },
                {
                  headers: {
                    Authorization: `Bearer ${player.token}`,
                  },
                },
              );
              setComments(comments.data);
              setCommentCount((prev) => ++prev);
              showConfettiText(); // Trigger the confetti effect
            } else {
              // console.log('else section');
              //Posts
              const comments = await axiosConn.put(
                '/posts/comment',
                {
                  comment: text,
                  postId,

                  image: SecurePostImgQ_AutoUrl,
                },
                {
                  headers: {
                    Authorization: `Bearer ${player.token}`,
                  },
                },
              );
              setComments(comments.data);
              setCommentCount((prev) => ++prev);
              showConfettiText(); // Trigger the confetti effect
            }
          });
          //console.log(comments);
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
        setText('');
        setCommentImage('');
      } else {
        setLoading(true);
        // do comment to backend here.
        try {
          if (parent === 'Meme') {
            // console.log('parent === Meme');

            const comments = await axiosConn.put(
              '/memes/comment',
              {
                comment: text,
                memeId,
                image: '',
              },
              {
                headers: {
                  Authorization: `Bearer ${player.token}`,
                },
              },
            );
            //console.log(comments.data);
            setComments(comments.data);
            setCommentCount((prev) => ++prev);
            showConfettiText(); // Trigger the confetti effect
          } else {
            const comments = await axiosConn.put(
              '/posts/comment',
              {
                comment: text,
                postId,
                image: '',
              },
              {
                headers: {
                  Authorization: `Bearer ${player.token}`,
                },
              },
            );
            //console.log(comments.data);
            setComments(comments.data);
            setCommentCount((prev) => ++prev);
            showConfettiText(); // Trigger the confetti effect
          }
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
        setText('');
        setCommentImage('');
      }
    }
  };
  return (
    <div className="create_comment_wrap">
      <ConfettiTextComponent />

      <div className={!mobileView ? 'create_comment' : 'create_commentMobile'}>
        <img
          // className={
          //   !mobileView
          //     ? 'createCommentProfileImg'
          //     : 'createCommentProfileImgMobile'
          // }
          src={
            player.data.player.profilePhoto
              ? player.data.player.profilePhoto
              : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
          }
          alt=""
        />
        <div
          className={
            !mobileView ? 'comment_input_wrap' : 'comment_input_wrapMobile'
          }
        >
          {picker && (
            <div className="comment_emoji_picker" ref={menu}>
              <Picker onEmojiClick={handleEmoji} />
            </div>
          )}
          <input
            type="file"
            hidden
            ref={imgInput}
            accept="image/jpeg,image/png,image/gif,image/webp"
            onChange={handleImage}
          />
          {error && (
            <div className="postError comment_error">
              <div className="postError_error">{error}</div>
              <button className="blue_btn" onClick={() => setError('')}>
                Try again
              </button>
            </div>
          )}
          <input
            className="commentInputText"
            type="text"
            ref={textRef}
            value={text}
            placeholder="Write a comment..."
            onChange={(e) => setText(e.target.value)}
            onKeyUp={handleComment}
            enterKeyHint="done"
          />
          <div className="comment_circle" style={{ marginTop: '5px' }}>
            <ClipLoader size={20} color="#1876f2" loading={loading} />
          </div>
          {/* <div className="comment_circle_icon hover2" onClick={handleComment}>
            <i className={!mobileView ? 'share_icon' : 'share_iconMobile'}></i>
          </div> */}
          <div
            className="comment_circle_icon hover2"
            onClick={() => {
              setPicker((prev) => !prev);
            }}
          >
            <i className="emoji_icon"></i>
          </div>
          <div
            className="comment_circle_icon hover2"
            onClick={() => imgInput.current.click()}
          >
            <i className="addPhoto_icon"></i>
          </div>
          {/*
          <div className="comment_circle_icon hover2">
            <i className="gif_icon"></i>
          </div>
          <div className="comment_circle_icon hover2">
            <i className="sticker_icon"></i>
          </div>
          */}
        </div>
      </div>
      {commentImage && (
        <div className="comment_img_preview">
          <img src={commentImage} alt="" />
          <div
            className="small_white_circle"
            onClick={() => setCommentImage('')}
          >
            <i className="exit_icon"></i>
          </div>
        </div>
      )}
    </div>
  );
}
