import './messenger.css';
import Topbar from '../../components/topbar/Topbar';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';
import { useReactMediaRecorder } from 'react-media-recorder';
import AdRotator from '../../components/adRotator/AdRotator';
import { useConfettiText } from '../../hooks/useConfettiText';
import Conversation from '../../components/conversations/Conversation';
import Message from '../../components/message/Message';
import ChatOnline from '../../components/chatOnline/ChatOnline';
import { useParams } from 'react-router-dom';
import Picker from 'emoji-picker-react';
import useClickOutside from '../../clickOutside';
import { ClipLoader } from 'react-spinners';
import { useIsTyping } from 'use-is-typing';
import LoadingBar from 'react-top-loading-bar';
import checkPageStatus from '../../utils/functions';
import getMobileOS from '../../utils/getMobileOs';
import { uploadProgress } from '../../utils/uploadProgress';
import { handleEmoji } from '../../utils/handleEmojis';
import ChatBoxMiddle from '../../components/chatBoxMiddle/ChatBoxMiddle';
import handleFileInput from '../../utils/handleFileInput';
import { debounce } from '../../context/ViewPortContext';

import {
  useContext,
  useEffect,
  //useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CircularProgress } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import {
  PermMedia,
  // Label,
  // Room,
  // EmojiEmotions,
  Cancel,
} from '@material-ui/icons';

import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import axiosConn from '../../axiosConn';
//import InputEmoji from 'react-input-emoji';
import { useMediaQuery } from 'react-responsive';
import { ViewPortContext, useViewport } from '../../context/ViewPortContext';
import { useQuery } from 'react-query';
import { useGetConversations } from '../../hooks/useGetConversations';
import { io } from 'socket.io-client';
import { SocketContext } from '../../context/socket';
import ChatBoxBottom from '../../components/chatBoxBottom/ChatBoxBottom';
import useMobileView from '../../hooks/useMobileView';
import { INCOMING_MESSAGE_SQUEAK_SOUND_URL2 } from '../../utils/constants';
import { MAX_VIDEO_SIZE, MAX_IMAGE_SIZE } from '../../config/constants';

const MAX_VIDEO_SIZE_MB = MAX_VIDEO_SIZE / (1024 * 1024); // Convert bytes to megabytes
const MAX_IMAGE_SIZE_MB = MAX_IMAGE_SIZE / (1024 * 1024); // Convert bytes to megabytes

const TYPING_SOUND_URL =
  'https://res.cloudinary.com/joegalaxy/video/upload/q_auto/v1668749345/Keyboard-Typing-00-c-FesliyanStudios.com_ercgzd.mp3';
const INCOMING_MESSAGE_SOUND_URL =
  'https://res.cloudinary.com/joegalaxy/video/upload/q_auto/v1668752008/incoming_message_gdjbwi.mp3';
// const INCOMING_MESSAGE_SQUEAK_SOUND_URL2 =
//   'https://res.cloudinary.com/joegalaxy/video/upload/v1709680919/Duck_Toy_Squeak_Dog_Toy_Sound_Effect_download_t5d4t5.mp3';

const MESSAGE_PARTICLE_COUNT = 100;
const maxVideoSize = MAX_VIDEO_SIZE; // 90mb
const maxImageSize = MAX_IMAGE_SIZE; // 20mb

export default function Messenger() {
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [receiverProfilePhoto, setReceiverProfilePhoto] = useState(null);

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  //const [newReceiverId, setNewReceiverId] = useState('');
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [onlinePlayers, setOnlinePlayers] = useState([]);
  const socket = useRef();
  const { player } = useContext(AuthContext);
  const socket2 = useContext(SocketContext);
  // const [receiverIsOnline, SetReceiverIsOnline] = useState('false');
  const scrollRef = useRef(null);
  // const messageEl = useRef(null);

  const mobileView = useMobileView();

  const { convoId } = useParams(); //grab conversationId

  const [isTyping, register] = useIsTyping({ timeout: 300 });
  const [isTypingDisplay, SetIsTypingDisplay] = useState(false);
  const [senderPlayerId, setSenderPlayerId] = useState(null);
  const [picker, setPicker] = useState(false);
  const [text, setText] = useState('');
  const [cursorPosition, setCursorPosition] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);
  const [progress, setProgress] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [messageCount, setMessageCount] = useState(10);
  const [conversationCount, setConversationCount] = useState(10);
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ audio: true });
  const [voiceMemo, setVoiceMemo] = useState(false);
  const [sendingFile, setSendingFile] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
  const [totalUnReadMessages, setTotalUnReadMessages] = useState(0);

  const os = getMobileOS();
  // console.log(os);
  const { width } = useContext(ViewPortContext);

  const textRef = useRef(null);
  const messageRef = useRef(null);

  const menu = useRef(null);
  const messageSoundRef = useRef(null);
  const typingSoundRef = useRef(null);
  const [showConfettiText, ConfettiTextComponent] = useConfettiText(
    !mobileView ? (voiceMemo ? messageRef : textRef) : messageRef,
    mobileView,
    '+1 JGK So Fun!',
    MESSAGE_PARTICLE_COUNT,
    'messenger',
    voiceMemo,
  );

  const handleClickOutside = () => {
    setPicker((prev) => !prev);
  };

  useClickOutside(menu, () => handleClickOutside());

  useEffect(() => {
    if (cursorPosition && textRef !== null)
      textRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  // const handleEmoji = (e, { emoji }) => {
  //   const ref = textRef.current;
  //   ref.focus();
  //   const start = text.substring(0, ref.selectionStart);
  //   const end = text.substring(ref.selectionStart);
  //   const newText = start + emoji + end;
  //   setText(newText);
  //   setCursorPosition(start.length + emoji.length);
  // };

  useEffect(() => {
    if (messages && messages.length > 0) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView(true);
      }, 100);
    }
  }, [messages]);

  useEffect(() => {
    //console.log(convoId);
    if (convoId && convoId !== `''`) {
      const getConversation = async () => {
        try {
          const res = await axiosConn.get(
            '/conversations/' + convoId + '/conversation',
            {
              headers: {
                Authorization: `Bearer ${player.token}`,
              },
            },
          );
          //console.log('Messenger: ', res.data.data.data);
          //check if logged in player is
          // a party to this conversation.
          // if not do not setCurrentChat.
          if (res.data.data.data.members.includes(player.data.player._id))
            setCurrentChat(res.data.data.data);
        } catch (err) {
          console.log(err);
        }
      };
      getConversation();
    }

    //console.log(convoId ? convoId : 'noParameter');
  }, [convoId, player.data.player._id, player.token]);
  //console.log('Messenger: ', player);

  useEffect(() => {
    let mounted = true;

    if (!messageSoundRef.current) {
      messageSoundRef.current = new Audio(INCOMING_MESSAGE_SQUEAK_SOUND_URL2);
    }

    const getMessage = (data) => {
      //Receiver side getMessage & setArrivalMessage
      // console.log(data);
      checkPageStatus(data.text, data.senderPlayerId);

      if (!mounted) return;

      const newMessage = {
        // conversationId: data.conversationId,
        sender: data.senderId,
        text: data.text,
        img: data.img,
        createdAt: Date.now(),
        messageId: data.messageId,
      };

      const isSenderInCurrentChat = currentChat?.members.includes(
        newMessage.sender,
      );

      if (isSenderInCurrentChat && isHistoryLoaded) {
        setMessages((prev) => [...prev, newMessage]);

        // Emit the 'messageRead' event after the message is added to the state
        socket2.emit('messageRead', {
          messageId: newMessage.messageId,
          isRead: true,
          senderId: newMessage.sender,
          receiverId: player.data.player._id,
        });

        // Call the refetch function to trigger a refetch in the Topbar component
        refetch();
      }

      //console.log(data);
      const playPromise = messageSoundRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Automatic playback started!
            // Additional actions if needed
          })
          .catch((error) => {
            // Auto-play was prevented
            // Additional actions if needed
            console.log(error);
          });
      }
    };

    // Add the event listener
    socket2.on('getMessage', getMessage);

    return () => {
      // Remove the event listener
      socket2.off('getMessage', getMessage);
      mounted = false;
    };
  }, [socket2, isHistoryLoaded, currentChat?.members, totalUnReadMessages]);

  useEffect(() => {
    // Listen for the 'messageRead' event
    socket2.on('messageReadByReceiver', (data) => {
      // console.log(data);
      // Check if the current user is the sender of the message
      if (data.senderId === player.data.player._id) {
        // Find the last message and any previous messages that have not been updated to read
        // and update their isRead status
        setMessages((prevMessages) =>
          prevMessages.map((message) =>
            message._id <= data.messageId && !message.isRead
              ? { ...message, isRead: data.isRead }
              : message,
          ),
        );
      }
    });

    // Clean up the event listener when the component unmounts
    return () => {
      socket2.off('messageRead');
    };
  }, [player.data.player._id, setMessages, socket2]);

  useEffect(() => {
    socket2.emit('addPlayer', player.data.player._id);
    socket2.on('getPlayers', (players) => {
      setOnlinePlayers(
        player.data.player.followings.filter((f) =>
          players.some((u) => u.playerId === f),
        ),
      );
    });
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket2.off('getPlayers');
    };
  }, [player, socket2]);

  useEffect(() => {
    // other player cleared chat messages
    // clearing on this end too.
    socket2.on('getClearChat', (conversationId) => {
      //console.log(conversationId);
      setMessages([]);
    });
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket2.off('clearChat');
    };
  }, [currentChat?._id, socket2]);

  useEffect(() => {
    //typing...
    let mounted = true;

    if (!typingSoundRef.current) {
      typingSoundRef.current = new Audio(TYPING_SOUND_URL);
    }

    socket2.on('display', (data) => {
      //console.log(data);
      //show typing if currentChat member is typing...
      if (!mounted) return;
      SetIsTypingDisplay(data.typing);
      setSenderPlayerId(data.senderPlayerId);

      if (data.typing) {
        typingSoundRef.current.play();
      } else {
        typingSoundRef.current.pause();
      }
    });

    return () => {
      mounted = false;
      socket2.off('display');
    };
  }, [player, socket2]);

  const onSuccess = (data) => {
    // console.log('data: ', data);
  };

  const onError = (err) => {
    // console.log('err: ', err);
  };

  const {
    data,
    isSuccess,
    status: conversationStatus,
    refetch,
  } = useGetConversations(onSuccess, onError, player);
  // console.log(data);

  useEffect(() => {
    //set conversations
    if (isSuccess) {
      // console.log(isSuccess);
      setConversations(data);
    }
  }, [data, isSuccess]);

  const getMessages = async () => {
    try {
      const res = await axiosConn.get('/messages/' + currentChat?._id, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      // console.log(
      //   res.data.sort((a, b) => {
      //     return new Date(a.createdAt) - new Date(b.createdAt);
      //   }),
      // );
      return res.data.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const {
    isLoading: isLoading2,
    data: data2,
    status: status2,
  } = useQuery(['getMessages', currentChat], getMessages, {
    // staleTime: 10000,
  });

  // console.log(data2);

  useEffect(() => {
    let mounted = true;

    if (mounted && status2 === 'success' && data2) {
      setMessages(data2);
      refetch();
      setIsHistoryLoaded(true);

      // Emit the 'messageRead' event for the last message from the sender
      const lastMessage = data2[data2.length - 1];
      if (lastMessage && lastMessage.sender !== player.data.player._id) {
        socket2.emit('messageRead', {
          messageId: lastMessage._id,
          isRead: true,
          senderId: lastMessage.sender,
          receiverId: player.data.player._id,
        });
      }
    }

    return () => {
      mounted = false;
    };
  }, [data2, status2]);

  const getConversationReceiver = async () => {
    try {
      const receiverId = currentChat?.members.find(
        (m) => m !== player.data.player._id,
      );
      //console.log('receiverId => ', receiverId);
      if (receiverId !== undefined) {
        const res2 = await axiosConn(`/players/${receiverId}`, {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        });
        const newReceiver = res2.data.data.data;
        //console.log(res2.data.data.data);
        return newReceiver.profilePhoto;
      }
      //console.log('receiverProfilePhoto => ', receiverProfilePhoto);
    } catch (err) {
      console.log(err);
    }
  };

  const {
    isLoading: isLoading3,
    data: data3,
    status: status3,
  } = useQuery(
    ['getConversationReceiver', currentChat],
    getConversationReceiver,
    {
      // staleTime: 10000,
    },
  );

  // console.log(data3);

  useEffect(() => {
    let mounted = true;
    if (status3 === 'success') {
      if (!mounted) return;
      setReceiverProfilePhoto(data3);
    }
    return () => {
      mounted = false;
      setReceiverProfilePhoto(null);
    };
  }, [data3, status3]);

  useEffect(() => {
    // useEffect for Typing...
    // console.log('typing?', isTyping);
    const receiverId = currentChat?.members.find(
      (member) => member !== player.data.player._id,
    );

    // console.log(player.data.player._id, receiverId, 'emit typing', isTyping);

    socket2.emit('typing', {
      senderId: player.data.player._id,
      senderPlayerId: player.data.player.playerId,
      receiverId,
      typing: isTyping,
    });
  }, [
    currentChat?.members,
    isTyping,
    player.data.player._id,
    player.data.player.playerId,
    socket2,
  ]);

  const handleSendAudioMemo = async (e) => {
    // e.preventDefault();
    // console.log('handleSendAudioMemo Top', e);
    let SecurePostImgQ_AutoUrl = '';
    const receiverId = currentChat.members.find(
      (member) => member !== player.data.player._id,
    );
    // console.log(receiverId);

    //convert mediaUrl to an .mp3.
    const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const fileName = Date.now() + '.mp3';
    const audioFile = new File([audioBlob], fileName, {
      type: 'audio/mpeg-3',
    });
    // then follow steps of handleSubmit.
    let data = new FormData(); // preparing to send to the server

    data.append('name', fileName);
    data.append('file', audioFile);
    data.append('upload_preset', 'cubejg');
    data.append('cloud_name', 'joegalaxy');

    const config = uploadProgress(setProgress, setUploadPercentage);

    try {
      let url = 'https://api.cloudinary.com/v1_1/joegalaxy/';

      url = url + 'video/upload';
      //console.log(url);

      //console.log(url);
      setLoading(true);

      await axios.post(url, data, config).then(async (data) => {
        //console.log(data);
        SecurePostImgQ_AutoUrl =
          data.data.secure_url.split('/upload/')[0] +
          '/upload/q_auto/fl_progressive:steep/' +
          data.data.secure_url.split('/upload/')[1];
        //console.log(SecurePostImgQ_AutoUrl);

        setProgress(100);
        setUploadPercentage(100);
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setText('');
      setError(err.message);
      console.log(err);
    }

    // console.log(SecurePostImgQ_AutoUrl);

    // if OnlinePlayers includes receiverId
    // isRead true, otherwise false.
    //setNewMessage(text);
    let message = {
      sender: player.data.player._id,
      text,
      img: SecurePostImgQ_AutoUrl,
      conversationId: currentChat._id,
      // isRead: false,
      // isRead: onlinePlayers?.includes(receiverId) ? true : false,
      isRead: false,
    };

    //console.log(message);
    try {
      socket2.emit('sendMessage', {
        senderId: player.data.player._id,
        senderPlayerId: player.data.player.playerId,
        receiverId,
        text,
        img: SecurePostImgQ_AutoUrl,
        isRead: false,
      });
      // checkPageStatus(text, player.data.player._id);
    } catch (err) {
      console.log(err);
    }

    // console.log(onlinePlayers.includes(receiverId) ? 'true' : 'false');
    try {
      setLoading(true);

      const res = await axiosConn.post(
        // `/messages/${onlinePlayers.includes(receiverId) ? 'true' : 'false'}`,
        '/messages/false',
        message,

        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      // console.log(res.data.data.data);
      // console.log(...messages);
      setMessages([...messages, res.data.data.data]);
      setLoading(false);

      setNewMessage('');
      setText('');
      setFile(null);
      fileInput.current.value = null;
      //  console.log('showConfettiText() calling');
      showConfettiText();
    } catch (err) {
      setLoading(false);
      setText('');
      setError(err.message);
      console.log(err);
    }

    //send audio file.
    setVoiceMemo(false);
    // setIsLoading(true);

    clearBlobUrl();
  };

  // // Higher-order debounce function
  // function debounce(func, wait) {
  //   let timeout;

  //   return function executedFunction(...args) {
  //     const later = () => {
  //       clearTimeout(timeout);
  //       func(...args);
  //     };

  //     clearTimeout(timeout);
  //     timeout = setTimeout(later, wait);
  //   };
  // }

  const handleSubmit = async (e) => {
    //e.preventDefault();
    //console.log(file);
    //setLoading(true);
    let SecurePostImgQ_AutoUrl = '';
    const trimmedText = text.trim();

    if (
      //if there is a text or a file continue here.
      (e.key === 'Enter' || e.type === 'click') &&
      (trimmedText !== '' || fileInput.current.value !== '')
      // &&
      // text !== undefined
    ) {
      const receiverId = currentChat.members.find(
        (member) => member !== player.data.player._id,
      );

      //if there is a file with the message.
      // upload to cloudinary, get the url
      // add img url to message object for socket.
      // post to createMessage with online or not
      if (file) {
        //console.log(file);
        if (
          file.type !== 'image/jpeg' &&
          file.type !== 'image/png' &&
          file.type !== 'image/webp' &&
          file.type !== 'image/gif' &&
          file.type !== 'video/mp4' &&
          file.type !== 'video/quicktime'
        ) {
          setError(`${file.name} format is not supported.`);
          return;
        }

        if (
          file.name.toLowerCase().includes('.mp4') ||
          file.name.toLowerCase().includes('.mov')
        ) {
          if (file.size > MAX_VIDEO_SIZE) {
            //1024 * 1024 * 90 = 90mb
            setError(
              `${file.name} is too large max ${MAX_VIDEO_SIZE_MB}mb allowed.`,
            );
            return;
          }
        } else if (file.size > MAX_IMAGE_SIZE) {
          //cloudinary max size. 20mb
          setError(
            `${file.name} is too large max ${MAX_IMAGE_SIZE_MB}mb allowed.`,
          );
          return;
        }

        let data = new FormData();
        // console.log(file);
        const fileName = Date.now() + file.name;
        data.append('name', fileName);
        data.append('file', file);
        data.append('upload_preset', 'cubejg');
        data.append('cloud_name', 'joegalaxy');

        const config = uploadProgress(setProgress, setUploadPercentage);
        try {
          let url = 'https://api.cloudinary.com/v1_1/joegalaxy/';
          if (
            fileName.toLowerCase().includes('.mp4') ||
            file.name.toLowerCase().includes('.mov')
          ) {
            url = url + 'video/upload';
            //console.log(url);
          } else {
            url = url + 'image/upload';
          }
          //console.log(url);
          setLoading(true);

          await axios.post(url, data, config).then(async (data) => {
            //console.log(data);
            SecurePostImgQ_AutoUrl =
              data.data.secure_url.split('/upload/')[0] +
              '/upload/q_auto/fl_progressive:steep/' +
              data.data.secure_url.split('/upload/')[1];
            //console.log(SecurePostImgQ_AutoUrl);
          });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setText('');
          setError(err.message);
          console.log(err);
        }
      }

      // console.log(SecurePostImgQ_AutoUrl);

      // if OnlinePlayers includes receiverId
      // isRead true, otherwise false.
      //setNewMessage(text);
      let message = {
        sender: player.data.player._id,
        text,
        img: SecurePostImgQ_AutoUrl,
        conversationId: currentChat._id,
        // isRead: false,
        isRead: false,
      };

      try {
        setLoading(true);

        const res = await axiosConn.post(
          '/messages/false',

          message,

          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // console.log(res.data.data.data._id);
        // console.log(...messages);
        setMessages([...messages, res.data.data.data]);
        setLoading(false);

        // Emit the 'sendMessage' event after the message is saved to the database
        socket2.emit('sendMessage', {
          senderId: player.data.player._id,
          senderPlayerId: player.data.player.playerId,
          receiverId,
          text,
          img: SecurePostImgQ_AutoUrl,
          messageId: res.data.data.data._id, // Include the message._id in the event
          isRead: false,
        });

        setNewMessage('');
        setText('');
        setFile(null);
        fileInput.current.value = null;
        setSendingFile(false);
        showConfettiText();
      } catch (err) {
        setLoading(false);
        setText('');
        setError(err.message);
        console.log(err);
        setSendingFile(false);
      }
    }
  };

  // Wrap handleSubmit with the debounce function
  const debouncedHandleSubmit = debounce(handleSubmit, 500);

  const showMore = () => {
    setMessageCount((prev) => prev + 10);
  };

  const showMoreConversations = () => {
    setConversationCount((prev) => prev + 10);
  };

  const handleMicClick = () => {
    // console.log('Microphone click', isRecording);
    setVoiceMemo(true);
    // isRecording = !isRecording;

    if (!isRecording) {
      // console.log('starting record.');
      startRecording();
    } else {
      // console.log('stopping record.');
      stopRecording();
    }
    setIsRecording((prev) => !prev);
  };

  const handleDeleteMessage = (messageId) => {
    setMessages(messages.filter((m) => m._id !== messageId));
  };
  //console.log(arrivalMessage, conversations);
  return (
    <>
      <Topbar
        conversationsData={data}
        conversationsStatus={conversationStatus}
        refetchConversations={refetch}
        parent="Messenger"
        conversations={conversations}
        setConversations={setConversations}
        totalUnReadMessages={totalUnReadMessages}
        setTotalUnReadMessages={setTotalUnReadMessages}
      />{' '}
      {/* Pass the data, status, and refetch function as props */}
      {/* <Topbar parent="Messenger" /> */}
      {mobileView && <TopbarNotification parent="Messenger" />}
      <div className={!mobileView ? 'messenger' : 'messengerMobile'}>
        <div className="chatMenu">
          <div className="chatMenuWrapper">
            {/* <input placeholder="Search for friends" className="chatMenuInput" /> */}
            {conversations
              ?.sort((p1, p2) => {
                return new Date(p2.updatedAt) - new Date(p1.updatedAt);
              })
              .slice(0, conversationCount)
              .map((c, i) => (
                <div
                  key={i}
                  // onClick={() => setCurrentChat(c)}
                >
                  <Conversation
                    onlinePlayers={onlinePlayers}
                    conversation={c}
                    currentPlayer={player}
                    setMessages={setMessages}
                    setCurrentChat={setCurrentChat}
                    currentChat={currentChat}
                    arrivalMessage={arrivalMessage}
                  />
                </div>
              ))}
            {conversationCount < conversations?.length && (
              <div
                className={
                  !mobileView ? 'viewPosts' : 'viewConversationsMobile'
                }
                onClick={() => showMoreConversations()}
              >
                View {conversations?.length - conversationCount} more
                conversations
              </div>
            )}
          </div>
        </div>
        <div className={!mobileView ? 'chatBox' : 'chatBoxMobile'}>
          <div className="chatBoxWrapper">
            <LoadingBar
              color="limegreen"
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />
            {isLoading ? (
              player?.data.player.jgkTokens > 999 && (
                <div>
                  {/* <CircularProgress color="inherit" size="10px" /> */}
                  <img
                    className="postImg"
                    //src={post.img}
                    src={
                      player?.data.player.jgkTokens > 9999
                        ? 'https://res.cloudinary.com/joegalaxy/image/upload/v1690738859/logo_transparency2_sdvx5i.png'
                        : player?.data.player.jgkTokens > 4999 &&
                          player?.data.player.jgkTokens < 10000
                        ? 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1683396920/logo_transparency4_nyioc3.png'
                        : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1680380660/imageedit_0_3957215681_osoxqy.png'
                    }
                    // width="100"
                    // height="100"
                    alt="Chirbichaw"
                    loading="eager"
                    // style={{
                    //   backgroundImage: `url("https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1672717524/loading-gif_sfv2dl.gif")`,
                    // }}
                  />
                </div>
              )
            ) : currentChat ? (
              <>
                <div className="chatBoxTop">
                  {messageCount < messages?.length && (
                    <div
                      className={
                        !mobileView ? 'viewMessages' : 'viewMessagesMobile'
                      }
                      onClick={() => showMore()}
                    >
                      View {messages?.length - messageCount} more messages
                    </div>
                  )}
                  {messages
                    ?.slice(
                      messages?.length - messageCount < 0
                        ? 0
                        : messages?.length - messageCount,
                      messages?.length,
                    )
                    .map((m, i) => (
                      <div className="messages" key={i}>
                        <Message
                          message={m}
                          own={m.sender === player.data.player._id}
                          senderProfilePhoto={player.data.player.profilePhoto}
                          receiverProfilePhoto={receiverProfilePhoto}
                          from="Messenger"
                          onDeleteMessage={handleDeleteMessage}
                          receiverId={currentChat?.members.find(
                            (member) => member !== player.data.player._id,
                          )}
                        />
                      </div>
                    ))}
                  <div className="bottomMessages" ref={scrollRef} />
                </div>
                {/* <hr className="shareHr" /> */}
                <ConfettiTextComponent />
                {isTypingDisplay && senderPlayerId && (
                  <span>{senderPlayerId} is typing...</span>
                )}
                <ChatBoxMiddle
                  isTypingDisplay={isTypingDisplay}
                  senderPlayerId={senderPlayerId}
                  status={status}
                  mediaBlobUrl={mediaBlobUrl}
                  loading={loading}
                  handleMicClick={handleMicClick}
                  isRecording={isRecording}
                  mobileView={mobileView}
                  width={width}
                  setVoiceMemo={setVoiceMemo}
                  clearBlobUrl={clearBlobUrl}
                  handleSendAudioMemo={handleSendAudioMemo}
                  fileInput={fileInput}
                  handleFileInput={(e) =>
                    handleFileInput(
                      e,
                      setError,
                      setFile,
                      maxVideoSize,
                      maxImageSize,
                    )
                  }
                  error={error}
                  setError={setError}
                  setFile={setFile}
                  setText={setText}
                  file={file}
                  setLoading={setLoading}
                  setIsLoading={setIsLoading}
                  setSendingFile={setSendingFile}
                  messageRef={messageRef}
                />
                {!voiceMemo && (
                  <ChatBoxBottom
                    voiceMemo={voiceMemo}
                    // mobileView={mobileView}
                    picker={picker}
                    menu={menu}
                    handleEmoji={(e, { emoji }) =>
                      handleEmoji(
                        e,
                        { emoji },
                        text,
                        setText,
                        textRef,
                        setCursorPosition,
                      )
                    }
                    textRef={textRef}
                    register={register}
                    text={text}
                    setText={setText}
                    handleSubmit={debouncedHandleSubmit}
                    loading={loading}
                    sendingFile={sendingFile}
                    handleMicClick={handleMicClick}
                    isRecording={isRecording}
                    setPicker={setPicker}
                    fileInput={fileInput}
                    setSendingFile={setSendingFile}
                    // width={width}
                    handleSendAudioMemo={handleSendAudioMemo}
                  />
                )}
              </>
            ) : (
              <div className="openConversation">
                <span
                  className={
                    !mobileView
                      ? 'noConversationText flex-inline'
                      : 'noConversationTextMobile flex-inline'
                  }
                >
                  Open a conversation to start a chat.
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="chatOnline">
          <div className="chatOnlineWrapper">
            <ChatOnline
              onlinePlayers={onlinePlayers}
              currentId={player.data.player._id}
              setCurrentChat={setCurrentChat}
              setIsLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
