import Home from './pages/home/Home';
import PostPage from './pages/post/PostPage';
import MemePage from './pages/memePage/memePage';
import Galaxies from './pages/galaxy/galaxy';
import GalaxyProfile from './pages/galaxyProfile/GalaxyProfile';

import Activate from './pages/activate/Activate';
import Login from './pages/login/Login';
import Profile from './pages/profile/Profile';
import Register from './pages/register/Register';
import Messenger from './pages/messenger/Messenger';
import Advertise from './pages/advertise/Advertise';
import JGKToken from './pages/jgkToken/JGKToken';
import Meme from './pages/meme/Meme';
import PlayerMap from './pages/playerMap/PlayerMap';
import Reset from './pages/reset/Reset';
import WindowFocusHandler from './windowFocusHandler';
import checkJWTExpiration from './checkJWTExpiration';
import FeedbackForm from './pages/feedback/Feedback';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from './context/AuthContext';
// import { useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

function App() {
  const { player } = useContext(AuthContext);
  const [inFocus, setInFocus] = useState(false);
  // const match = useRouteMatch();
  const location = useLocation();
  const from = localStorage.getItem('from');
  // const history = useHistory();

  // console.log(match.path); // logs the path pattern ("/" or "/galaxies/:galaxyId", etc.)
  // console.log(match.url); // logs the matched portion of the URL
  // console.log(location.pathname); // logs the current URL path ("/" or "/galaxies/:galaxyId", etc.
  const Logoff = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  // useEffect(() => {
  //   if ('Notification' in window) {
  //     console.log('API Supported.');
  //   } else {
  //     console.log('API NOT Supported.');
  //   }
  // }, []);

  useEffect(() => {
    if (inFocus && player) {
      // console.log('Tab in focus App.js');
      if (!checkJWTExpiration()) {
        // console.log('logout');
        Logoff();
      }
    }
    return () => {};
  }, [inFocus, player]);

  // const unlockedGalaxies = localStorage.getItem('unlockedGalaxies');
  // const originFrom = localStorage.getItem('originalFrom');

  // console.log('App.js', location.pathname, 'from:', from);
  return (
    <>
      <WindowFocusHandler setInFocus={setInFocus} />
      <Router>
        <Switch>
          <Route exact path="/">
            {player ? (
              from !== null || from !== 'null' ? (
                <Redirect to={from} />
              ) : (
                <Home />
              )
            ) : (
              <Register />
            )}
          </Route>
          <Route exact path="/galaxies/:galaxyId">
            {!player ? (
              <Redirect to={`/login?from=${location.pathname}`} />
            ) : (
              <GalaxyProfile />
            )}
          </Route>
          <Route path="/galaxies">
            {!player ? <Redirect to="/login" /> : <Galaxies />}
          </Route>
          <Route exact path="/post/:postId">
            {player ? <PostPage /> : <Login />}
          </Route>
          <Route exact path="/meme/:memeId">
            {player ? <MemePage /> : <Login />}
          </Route>
          <Route path="/login">
            {player ? (
              <Redirect
                to={location.pathname !== '/login' ? location.pathname : '/'}
              />
            ) : (
              <Login />
            )}
          </Route>
          <Route path="/register/:token">
            {player ? <Redirect to="/" /> : <Register />}
          </Route>
          <Route path="/messenger/:convoId">
            {!player ? <Redirect to="/login" /> : <Messenger />}
          </Route>
          <Route path="/profile/:playerId">
            {/* <Profile /> */}
            {!player ? <Redirect to="/" /> : <Profile />}
          </Route>

          <Route path="/advertise">
            {!player ? <Redirect to="/" /> : <Advertise />}
          </Route>
          <Route path="/feedback">
            {!player ? <Redirect to="/login" /> : <FeedbackForm />}
          </Route>
          <Route path="/jgktoken">
            {!player ? <Redirect to="/" /> : <JGKToken />}
          </Route>
          <Route path="/meme">
            {!player ? <Redirect to="/login" /> : <Meme />}
          </Route>
          <Route path="/playermap">
            {!player ? <Redirect to="/" /> : <PlayerMap />}
          </Route>
          <Route path="/activate/:token">
            {!player ? <Redirect to="/" /> : <Activate />}
          </Route>

          <Route path="/reset">{<Reset />}</Route>
          <Route path="*">{player ? <Home /> : <Register />} </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
