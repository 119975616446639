import { useEffect, useState, useContext } from 'react';
import axiosConn from '../../axiosConn';
import { Link, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import validator from 'validator';
import './register.css';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import Footer from '../../components/footer/Footer';
import { PrivacyLink } from '../../components/footer/FooterStyles';
import SpotifyIframeNormalBlack from '../../utils/spotifyIframe';
import { ViewPortContext } from '../../context/ViewPortContext';
import fromToImg from '../../utils/fromToImg';

export default function Register() {
  const { token } = useParams();
  // console.log(token);
  const { height } = useContext(ViewPortContext);
  // console.log(height);
  const [loading, setLoading] = useState(true);

  const playerInfo = {
    playerId: '',
    emailAddress: '',
    referringEmailAddress: '',
    phoneNumber: '',
    password: '',
    passwordConfirm: '',
    bYear: new Date().getFullYear(),
    bMonth: new Date().getMonth() + 1,
    bDay: new Date().getDate(),
    gender: '',
  };
  const [player, setPlayer] = useState(playerInfo);
  const [gotreferringEmailAddress, SetGotReferringEmailAddress] =
    useState('joe@joegalaxy.com');
  const {
    playerId,
    emailAddress,
    referringEmailAddress,
    phoneNumber,
    password,
    passwordConfirm,
    bYear,
    bMonth,
    bDay,
    gender,
  } = player;

  const history = useHistory();
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const yearTemp = new Date().getFullYear();
  const [hasDateChanged, setHasDateChanged] = useState(false);
  const from = localStorage.getItem('from');

  useEffect(() => {
    getReferringEmailAddress();
  }, []);

  const getReferringEmailAddress = async () => {
    // console.log(token);
    setPlayer({ ...player, referringEmailAddress: 'joe@joegalaxy.com' });

    try {
      setLoading(true);

      if (token.length === 5) {
        // console.log(token);
        const res = await axiosConn.get(
          '/invites/getReferringEmailAddress/' + token,
          // { token: token },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // console.log(res.data.data.data);
        if (res.data.data.data) {
          // console.log(res.data.data.data);
          SetGotReferringEmailAddress(res.data.data.data);
          setPlayer({ ...player, referringEmailAddress: res.data.data.data });
        }
        // else {
        //   console.log('no referring email address');
        //   // SetGotReferringEmailAddress(res.data.referringEmailAddress);
        // }

        setLoading(false);
      }
      // else console.log(token);
    } catch (error) {
      // setError(error.response.data.message);
      // setError('no referring email');
      setLoading(false);
    }
  };

  const years = Array.from(new Array(108), (val, index) => yearTemp - index);
  const months = Array.from(new Array(12), (val, index) => 1 + index);
  const getDays = () => {
    return new Date(bYear, bMonth, 0).getDate();
  };

  const days = Array.from(new Array(getDays()), (val, index) => 1 + index);

  const isOldEnough = (minAge) => {
    const years = new Date().getFullYear() - bYear;
    const months = new Date().getMonth() + 1 - bMonth;
    const days = new Date().getDate() - bDay;
    // console.log(years);
    // Adding console.log to display age in years, months, and days
    // console.log(`Age: ${years} years, ${months} months, ${days} days`);

    if (years < minAge) {
      //not enough years.
      return false;
    }

    if (years === 13) {
      //exact minimum years..
      if (months < 0) {
        //enough years but not enough months.
        return false;
      }
      if (days < 0) {
        //enough years & months but not enough days.
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    // Only check age and set error if the date has been changed at least once
    if (hasDateChanged) {
      if (!isOldEnough(player.bDay, player.bMonth, player.bYear, 13)) {
        // console.log('not old enough');
        setError('Must be at least 13 years old');
      } else {
        // console.log('old enough');
        setError(''); // Clear error if previously set
      }
    }
    setHasDateChanged(true);
  }, [player.bDay, player.bMonth, player.bYear]); // Dependencies array, effect runs when these values change

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;

    if (name === 'bDay' || name === 'bMonth' || name === 'bYear') {
      setPlayer({ ...player, [name]: parseInt(value) });
    } else if (
      name === 'playerId' &&
      (value.length < 6 || !validator.isAlpha(value))
    ) {
      setError('PlayerId length must be at least 6 & only Alpha Characters.');
      return;
    } else if (name === 'emailAddress' && !validator.isEmail(value)) {
      setError('Please enter a valid email address');
      return;
    } else if (name === 'phoneNumber' && !validator.isMobilePhone(value)) {
      setError('Please enter a valid phone number');
      return;
    } else if (name === 'password' && !validator.isStrongPassword(value)) {
      setError(
        'Please enter a strong password.  minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1',
      );
      return;
    } else if (name === 'passwordConfirm' && value !== player.password) {
      setError('Passwords entered do not match');
      return;
    } else {
      setError('');
      if (name === 'referringEmailAddress' && value === '') {
        setPlayer({ ...player, referringEmailAddress: 'joe@joegalaxy.com' });
      } else {
        setPlayer({ ...player, [name]: value });
      }
    }
  };

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const nestHubView = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  const handleClick = async (e) => {
    e.preventDefault();
    setIsFetching(true);

    // console.log(gotreferringEmailAddress);
    // setPlayer({
    //   ...player,
    //   referringEmailAddress: gotreferringEmailAddress,
    // });
    //console.log(playerId);
    if (!validator.isAlpha(playerId)) {
      setError('Please enter alpha characters only for playerId.');
      setIsFetching(false);
      return;
    }

    if (!validator.isEmail(player.emailAddress)) {
      setError('Please enter a valid email address');
      setIsFetching(false);
      return;
    }
    if (!validator.isEmail(player.referringEmailAddress)) {
      setError('Please enter a valid referring email address');
      setIsFetching(false);
      return;
    }
    if (!validator.isMobilePhone(player.phoneNumber)) {
      setError('Please enter a valid phone number');
      setIsFetching(false);
      return;
    }

    if (!validator.isStrongPassword(player.password)) {
      setError(
        'Please enter a strong password.  minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1',
      );
      setIsFetching(false);
      return;
    }

    if (player.password !== player.passwordConfirm) {
      setError('Passwords entered do not match');
      setIsFetching(false);
      return;
    }

    // if age < 13 return error must be at least 13.
    if (!isOldEnough(13)) {
      setError('Must be at least 13 years old');
      setIsFetching(false);
      return;
    }
    if (player.gender === '') {
      setError('Please choose a gender');
      setIsFetching(false);
      return;
    }
    // console.log(player);
    try {
      await axiosConn.post('/players/signup', player);
      history.push('/login');
      setIsFetching(false);
    } catch (err) {
      setError(err.response.data.message);
      setIsFetching(false);
      console.log(err);
    }
    // console.log(player);
  };

  // console.log(player);
  return (
    <div className={!mobileView ? 'register' : 'registerMobile'}>
      <div
        className={!mobileView ? 'registerWrapper' : 'registerWrapperMobile'}
      >
        <div className="registerLeft">
          <div>
            <img src={fromToImg(from, 'Register')} alt="Joe Galaxy Logo"></img>
          </div>
          {/* <h3 className="loginLogo">JOE GALAXY</h3> */}
          <span className="registerDesc">
            <p>This game is your Life!</p>
            <p className="tag">See you at the Top...</p>
          </span>
          {/* <SpotifyIframeNormalBlack /> */}
        </div>

        <div className="registerRight">
          <div className="logoDescMobile">
            <div>
              <img
                src={fromToImg(from, 'Register')}
                alt="Joe Galaxy Logo"
              ></img>
            </div>
            {/* <h3 className="loginLogo">JOE GALAXY</h3> */}
            <span className="loginDesc">
              <p>This game is your Life!</p>
              <p className="tag">See you at the Top...</p>
            </span>
          </div>

          <form className="registerBox" onSubmit={handleClick}>
            <div className="reg_line">
              <input
                placeholder="PlayerId"
                required
                name="playerId"
                className="loginInput"
                onChange={handleRegisterChange}
              />
            </div>
            <div className="reg_line">
              <input
                placeholder="Email"
                required
                name="emailAddress"
                className="loginInput"
                type="email"
                onChange={handleRegisterChange}
              />
            </div>
            <div className="reg_line">
              <input
                placeholder="Phone"
                required
                name="phoneNumber"
                className="loginInput"
                type="phone"
                onChange={handleRegisterChange}
              />
            </div>
            <div className="reg_line">
              <input
                placeholder="Password"
                required
                name="password"
                className="loginInput"
                type="password"
                minLength="8"
                onChange={handleRegisterChange}
              />
            </div>
            <div className="reg_line">
              <input
                placeholder="Password Confirm"
                required
                name="passwordConfirm"
                className="loginInput"
                type="password"
                onChange={handleRegisterChange}
              />
            </div>
            <div className="reg_line">
              <input
                placeholder="Referring emailAddress"
                // required
                name="referringEmailAddress"
                className="loginInput"
                // type="email"
                onChange={handleRegisterChange}
                disabled={
                  gotreferringEmailAddress !== 'joe@joegalaxy.com'
                    ? true
                    : false
                }
                title={gotreferringEmailAddress}
                type={
                  // gotreferringEmailAddress !== 'joe@joegalaxy.com'
                  validator.isEmail(gotreferringEmailAddress)
                    ? 'hidden'
                    : 'email'
                }
              />
            </div>
            <div className="reg_col">
              <div className="reg_line_header">
                Date of birth <i className="info_icon"></i>
              </div>
              <div className="reg_grid">
                <select
                  name="bDay"
                  value={bDay}
                  onChange={handleRegisterChange}
                >
                  {days.map((day, i) => (
                    <option value={day} key={i}>
                      {day}
                    </option>
                  ))}
                </select>
                <select
                  name="bMonth"
                  value={bMonth}
                  onChange={handleRegisterChange}
                >
                  {months.map((month, i) => (
                    <option value={month} key={i}>
                      {month}
                    </option>
                  ))}
                </select>
                <select
                  name="bYear"
                  value={bYear}
                  onChange={handleRegisterChange}
                >
                  {years.map((year, i) => (
                    <option value={year} key={i}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="reg_col">
                <div className="reg_line_header">
                  Gender <i className="info_icon"></i>
                </div>
                <div className="reg_grid">
                  <label htmlFor="male">
                    Male
                    <input
                      type="radio"
                      name="gender"
                      id="male"
                      value="male"
                      onChange={handleRegisterChange}
                    />
                  </label>
                  <label htmlFor="female">
                    Female
                    <input
                      type="radio"
                      name="gender"
                      id="female"
                      value="female"
                      onChange={handleRegisterChange}
                    />
                  </label>
                </div>
              </div>
              <div className="reg_info">
                By clicking Sign Up, you agree to our{' '}
                <PrivacyLink
                  href="https://www.termsfeed.com/live/3d1dff9b-43cf-411f-8bd0-d96a8df1d4d1"
                  target="_blank"
                >
                  Terms, Privacy, & Data Policy. &nbsp;
                </PrivacyLink>
                You may receive SMS & Email notifications from us and can opt
                out at any time.
              </div>
            </div>
            {error && <div className="error_text">{error}</div>}

            <button
              className="registerButton"
              type="submit"
              disabled={isFetching}
            >
              {isFetching ? (
                <CircularProgress color="inherit" size="20px" />
              ) : (
                'Sign Up'
              )}
            </button>
            <div className="registerCenter">
              <Link
                to="/login"
                style={{ textDecoration: 'none' }}
                title="Login to Joe Galaxy"
              >
                <button className="loginRegisterButton">
                  Log into Account
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
      {!mobileView && height >= 875 && <Footer />}
    </div>
  );
}
