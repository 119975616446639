import { useContext, useEffect, useRef, useState } from 'react';
import { SocketContext } from '../../context/socket';
import SendVerification from '../../components/sendVerification/SendVerification';
import BTCTracker from '../../components/btcTracker/BTCTracker';
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import AdRotator from '../adRotator/AdRotator';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Message from '../../components/message/Message';
import ReactPlayer from 'react-player/lazy';
import isObjectEmpty from '../../utils/isObjectEmpty';
import { useGetCurrPlayer } from '../../hooks/useGetCurrPlayer';
import { useGetPosts } from '../../hooks/useGetPosts';
import {
  Chat,
  // Notifications
} from '@material-ui/icons';
import Post from '../post/Post';
import Share from '../share/Share';
import './feed.css';
import axiosConn from '../../axiosConn';
import Friendship from '../friendship/Friendship';
import DynamicHtml from '../dynamicHtml/DynamicHtml';
import { AuthContext } from '../../context/AuthContext';
import { ViewPortContext } from '../../context/ViewPortContext';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useMediaQuery } from 'react-responsive';
import { format } from 'timeago.js';
import MemeRotator from '../memeRotator/MemeRotator';
import JGKLeaderBoard from '../jgkLeaderBoard/JGKLeaderBoard';
import SpotifyIframeNormalBlack from '../../utils/spotifyIframe';
import getMonthDiff from '../../utils/getMonthDiff';
import { useUpdateCheck } from 'react-update-notification';
import MemoryPostRotator from '../memoryPostRotator/MemoryPostRotator';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import InstallButton from '../installButton/installButton';
const AUDIO_URL =
  'https://res.cloudinary.com/joegalaxy/video/upload/q_auto/v1668752473/korean_sms_cute_ucpzu9.mp3';
const AUDIO_FORMATS = /.(webm|mp3|wav)$/;
const VIDEO_FORMATS = /.(webm|mp4|mov)$/;
const IMAGE_FORMATS = /.(jpeg|jpg|gif|png)$/;

export default function Feed({
  playerId,
  parent = null,
  galaxy = null,
  subscriptionStatus = 'Subscribe',
}) {
  // console.log(parent, galaxy, playerId);
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState({});
  // const [postClicked, setPostClicked] = useState(false);

  const [isFriend, setIsFriend] = useState(false);
  const audioRef = useRef(null);
  const { player } = useContext(AuthContext);
  const [isFetching, setIsFetching] = useState(false);
  const [visitedPlayer, setVisitedPlayer] = useState({});
  const { width } = useContext(ViewPortContext);
  const [friends, setFriends] = useState([]);
  const [myFriends, SetMyFriends] = useState([]);
  const [visitedPlayerIsSet, SetVisitedPlayerIsSet] = useState(false);
  const socket = useRef();
  const [onlinePlayers, setOnlinePlayers] = useState([]);
  const [myOnlineFriends, SetMyOnlineFriends] = useState([]);
  const [postCount, setPostCount] = useState(10);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [myOnlineFriendThatMessaged, SetMyOnlineFriendThatMessaged] = useState(
    [],
  );
  const [convoId, setConvoId] = useState('');
  const [messageTextIsVideo, setMessageTextIsVideo] = useState(false);
  const [randomNum, setRandomNum] = useState(0);
  const [randomNumTwo, setRandomNumTwo] = useState(0);
  const [randomNumAd, setRandomNumAd] = useState(0);
  const [currPlayer, setCurrPlayer] = useState({});
  const [isAudio, setIsAudio] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [showForm, setShowForm] = useState(false); // New state for the form visibility

  const socket2 = useContext(SocketContext);
  //const [thePosts, setThePosts] = useState(posts);
  const [sortBy, setSortBy] = useState(0);
  const [memoryPosts, SetMemoryPosts] = useState([]);
  const [page, setPage] = useState(1);
  const newPostsRef = useRef([]);

  const [postItems, setPostItems] = useState([]);
  const [socketPostIds, setSocketPostIds] = useState([]);
  //memory posts since # of months back.
  const d = new Date();
  const [memoryPostMonths, setMemoryPostMonths] = useState(
    getMonthDiff(new Date(2022, 2), new Date(d.getFullYear(), d.getMonth())),
  );
  const [trigger, setTrigger] = useState(0);
  const [isGalaxyMember, setIsGalaxyMember] = useState(
    galaxy?.members.includes(player.data.player._id),
  );

  useEffect(() => {
    // console.log(galaxy?.members.includes(player.data.player._id));
    setIsGalaxyMember(galaxy?.members.includes(player.data.player._id));
  }, [galaxy, player.data.player._id]);

  useEffect(() => {
    if (isGalaxyMember) {
      setTrigger((prevTrigger) => prevTrigger + 1);
    }
  }, [isGalaxyMember]);

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  useEffect(() => {
    let mounted = true;
    if (!audioRef.current) {
      audioRef.current = new Audio(AUDIO_URL);
    }

    socket2.emit('addPlayer', player.data.player._id);
    socket2.on('getPlayers', (players) => {
      //   console.log(players);
      //   console.log(player);
      if (!mounted) return;
      setOnlinePlayers(
        player.data.player.followings.filter((f) =>
          players.some((u) => u.playerId === f),
        ),
      );
    });

    socket2.on('getMessage', (data) => {
      //console.log(data);
      if (!mounted) return;
      setArrivalMessage({
        // conversationId: data.conversationId,
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
        img: data.img,
      });
      audioRef.current.play();

      // audio.play();
      // const length = data?.img?.split('.')?.length;
      // console.log(data.img);
      const isAudio = AUDIO_FORMATS.test(data?.img);
      setIsAudio(isAudio);

      const isVideo = VIDEO_FORMATS.test(data?.img);
      setIsVideo(isVideo);

      const isImage = IMAGE_FORMATS.test(data?.img);
      setIsImage(isImage);
    });

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket2.off('getPlayers');
      socket2.off('getMessage');
      mounted = false;
    };
  }, [player, socket2]);

  const onSuccess = (data) => {
    // console.log('data: ', data);
  };

  const onError = (err) => {
    // console.log('err: ', err);
  };

  const { data: data2, status: status2 } = useGetCurrPlayer(
    onSuccess,
    onError,
    player,
  );
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    //set conversations
    if (status2 === 'success') {
      if (!mounted) return;
      setCurrPlayer(data2);
    }
    return () => {
      mounted = false;
    };
  }, [status2, data2]);

  useEffect(() => {
    let mounted = true;
    const getVisitedPlayer = async () => {
      try {
        const res = await axiosConn.get(`/players/?playerId=${playerId}`, {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        });
        if (!mounted) return;
        setVisitedPlayer(res.data.data.data[0]);
        SetVisitedPlayerIsSet(true);
        // console.log('visitedPlayer: ', res.data.data.data[0]);
        //setIsDone1(true);
      } catch (err) {
        SetVisitedPlayerIsSet(false);
        console.log(err);
      }
    };
    getVisitedPlayer();
    return () => {
      mounted = false;
    };
  }, [player.data.player._id, player.token, playerId]);

  useEffect(() => {
    if (
      visitedPlayerIsSet &&
      visitedPlayer &&
      typeof visitedPlayer._id !== 'undefined'
    ) {
      //This grabs visitedPlayer's friends.
      const getFriends = async () => {
        try {
          const friendList = await axiosConn.get(
            '/players/friends/' + visitedPlayer._id,
            {
              headers: {
                Authorization: `Bearer ${player.token}`,
              },
            },
          );
          setFriends(friendList.data);
          //console.log(friendList.data);
        } catch (err) {
          console.log(err);
        }
      };
      getFriends();
    }
  }, [visitedPlayer, player.token, visitedPlayerIsSet]);

  useEffect(() => {
    let mounted = true;
    //This grabs logged on player's friends.
    const getMyFriends = async () => {
      try {
        const friendList = await axiosConn.get(
          '/players/friends/' + player.data.player._id,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        if (!mounted) return;
        SetMyFriends(friendList.data);
        // console.log(friendList.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMyFriends();
    return () => {
      mounted = false;
    };
  }, [player.token, player.data.player._id]);

  useEffect(() => {
    //console.log(onlinePlayers);
    SetMyOnlineFriends(myFriends.filter((f) => onlinePlayers.includes(f._id)));
    //console.log(friends);
    //console.log(onlineFriends);
  }, [myFriends, onlinePlayers]);

  useEffect(() => {
    //when arrivalMessage changes
    //setMyOnlineFriendThatMessaged if arrivalMessage sender
    // is one of myOnlineFriends. arrivalMessage only dependency.
    SetMyOnlineFriendThatMessaged(
      myOnlineFriends.filter((f) => f._id === arrivalMessage?.sender),
    );
  }, [
    arrivalMessage,
    //  myOnlineFriends
  ]);

  // Click handler for the Post component
  const handlePostClick = () => {
    // console.log('setPostClicked(true)');
    // setPostClicked(true);
  };

  useEffect(
    () => {
      let mounted = true;
      const handleFocus = () => {
        if (
          !mounted
          // || postClicked
        )
          return;
        setRandomNumTwo(Math.floor(Math.random() * 2));
      };

      const handleBlur = () => {
        if (!mounted) return;
        // console.log('setPostClicked(false)');
        // setPostClicked(false); // Set postClicked back to false when the tab loses focus
      };

      // // Add the event listener when the component mounts
      window.addEventListener('focus', handleFocus);
      window.addEventListener('blur', handleBlur);

      // Remove the event listener when the component unmounts
      return () => {
        mounted = false;
        window.removeEventListener('focus', handleFocus);
        window.removeEventListener('blur', handleBlur);
      };
    },
    [
      // postClicked
    ],
  );

  const { isLoading, data, status } = useGetPosts(
    onSuccess,
    onError,
    player,
    playerId,
    page,
    parent === 'galaxy' ? 'galaxyPosts' : 'posts', // Add this line
    parent === 'galaxy' ? galaxy._id : null,
    trigger,
  );

  // Reset page to 1 whenever playerId changes
  useEffect(() => {
    setPage(1);
    setPostItems([]);
  }, [playerId]);

  useEffect(() => {
    let mounted = true;
    if (status === 'success' && mounted && data) {
      // Check that data is defined
      if (data.length > 0) {
        setPostItems((prevPosts) => {
          const existingPostIds = prevPosts.map((post) => post._id);
          const newPosts = data.filter(
            (post) =>
              !existingPostIds.includes(post._id) &&
              !socketPostIds.includes(post._id), // Add socketPostIds logic
          );
          return [...prevPosts, ...newPosts].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );
        });
      } else {
        console.log('No more galaxy posts to load.');
      }
    }
    return () => {
      mounted = false;
    };
  }, [status, data, socketPostIds]); // Add socketPostIds to the dependency array

  useEffect(() => {
    //checkIfFriends
    try {
      const checkIfFriends = async () => {
        setIsFetching(true);
        // console.log(playerId);
        if (playerId && typeof playerId !== 'undefined') {
          // console.log(playerId);

          const res1 = await axiosConn.get(`/players/?playerId=${playerId}`, {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          });
          setIsFetching(false);

          setIsFriend(
            res1.data.data.data[0]?.friends.includes(player.data.player._id),
          );
        }
      };
      checkIfFriends();
    } catch (error) {}
  }, [player.data.player._id, player.token, playerId]);

  const showMore = () => {
    setPostCount((prev) => prev + 10);
  };

  const goToMessenger = (convoId) => {
    // console.log(convoId);
    setConvoId(convoId);
    convoId === "''"
      ? (window.location.href = `/messenger/''`)
      : (window.location.href = `/messenger/${convoId}`);
    //window.location.href = `/messenger`;
    //next step pass the conversation as a param
    //messenger will grab param and setCurrentChat
    //once done with this remove <Link on span below
  };

  const getConversationGoToMessenger = async () => {
    //console.log('createConversationIfNoneCalled()...');

    if (myOnlineFriendThatMessaged[0]?._id === undefined) goToMessenger("''");

    try {
      // console.log(player.data.player._id);
      await axiosConn
        .get(
          `/conversations/find/${player.data.player._id}/${myOnlineFriendThatMessaged[0]._id}`,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        )
        .then(async (res) => {
          //console.log(res.data);

          goToMessenger(res.data._id);
          // console.log(res.data._id);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSortTopRanked = () => {
    // weighted based on post likes, comments, views.
    // console.log('handleSortTopRanked');
    const sortedPosts = [...posts]?.sort((a, b) => {
      return (
        b.likes.length * 2 +
        b.comments.length * 4 +
        b.views * 0.25 +
        (b.rePosts.length + b.reShares.length) * 20 +
        b.jgkTokens -
        (a.likes.length * 2 +
          a.comments.length * 4 +
          a.views * 0.25 +
          (a.rePosts.length + a.reShares.length) * 20 +
          a.jgkTokens)
      );
    });
    setPosts(sortedPosts);
    setSortBy(1);
    setPostCount(10);
    // console.log(sortedPosts);
  };

  const handleSortNewest = () => {
    if (!Array.isArray(posts)) {
      console.error('Posts is not an array:', posts);
      return;
    }
    // console.log('handleSortNewest');
    const sortedPosts = [...posts]?.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setPosts(sortedPosts);
    setSortBy(0);
    setPostCount(10);

    // console.log(sortedPosts);
  };

  //console.log(myOnlineFriends);
  // console.log(myOnlineFriendThatMessaged[0]?.playerId);
  // useEffect(() => {
  //   setRandomNum(Math.floor(Math.random() * memoryPosts.length));
  //   return () => {
  //     setRandomNum(0); // index 0-6 months ago for memory post.
  //   };
  // }, [memoryPosts.length]);

  // //share component setNewPost
  // //this user is sharing a post and feed will update with new post
  useEffect(() => {
    if (!isObjectEmpty(newPost)) {
      setPosts((prev) => [...prev, newPost]);
      // console.log(newPost);
    }
  }, [newPost]);

  useEffect(() => {
    // if (posts) {
    handleSortNewest();
    // }
  }, [posts?.length]);

  // //new post by socket.
  // useEffect(() => {
  //   socket2.on('getPost', (post) => {
  //     setSocketPostIds((prev) => [...prev, post._id]);

  //     if (
  //       myFriends?.length > 0 &&
  //       myFriends.filter((friend) => friend.playerId === post.playerId)
  //     ) {
  //       // console.log('poster is one of my friends');
  //       setPostItems((prev) => [...prev, post]);
  //     }
  //   });

  //   return () => {
  //     socket2.off('getPost');
  //   };
  // }, [myFriends, player.data.player.playerId, socket2]);

  useEffect(() => {
    socket2.on('getPost', (post) => {
      setSocketPostIds((prev) => [...prev, post._id]);
      // console.log(post);
      if (
        myFriends?.length > 0 &&
        myFriends.filter((friend) => friend.playerId === post.playerId)
      ) {
        newPostsRef.current = [...newPostsRef.current, post];
      }
    });

    return () => {
      socket2.off('getPost');
    };
  }, [myFriends, player.data.player.playerId, socket2]);

  // New useEffect for post updates
  useEffect(() => {
    socket2.on('getPostUpdates', (updatedPost) => {
      if (!updatedPost || !updatedPost._id) {
        console.error('Invalid post update received:', updatedPost);
        return;
      }
      // console.log('getPostUpdates', updatedPost);

      // Update the relevant post in the state
      setPostItems((prevPostItems) => {
        const updatedPostItems = prevPostItems.map((post) =>
          post._id === updatedPost._id ? updatedPost : post,
        );
        // console.log('Updated post items:', updatedPostItems);
        return updatedPostItems;
      });
    });

    return () => {
      socket2.off('getPostUpdates');
    };
  }, [socket2]);

  useEffect(() => {
    socket2.on('removePost', (post) => {
      if (!post || !post._id) {
        console.error('Invalid post received:', post);
        return;
      }
      // console.log('removePost', post);
      // Update state to remove the post
      setSocketPostIds((prev) => prev.filter((id) => id !== post._id));
      setPostItems((prevPostItems) =>
        prevPostItems.filter((p) => p._id !== post._id),
      );
      newPostsRef.current = newPostsRef.current.filter(
        (p) => p._id !== post._id,
      );
    });

    return () => {
      socket2.off('removePost');
    };
  }, [socket2]);

  useEffect(() => {
    handleScroll();
  }, [newPostsRef.current]);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Get the first post element
    const firstPost = document.querySelector('.post'); // Using the 'post' class as the selector
    // console.log('handleScroll', scrollTop, firstPost);

    // Calculate the position of the bottom of the first post relative to the top of the page
    const firstPostBottom = firstPost
      ? firstPost.getBoundingClientRect().top +
        firstPost.getBoundingClientRect().height +
        scrollTop
      : 0;
    // console.log(
    //   'firstPostBottom',
    //   firstPostBottom,
    //   'newPostsRef.current.length',
    //   newPostsRef.current.length,
    // );

    if (scrollTop <= firstPostBottom && newPostsRef.current.length > 0) {
      // console.log(
      //   'At the top of the page and new posts are available.',
      //   newPostsRef.current.length,
      //   newPostsRef.current,
      // );
      setPostItems((prevPosts) => {
        const existingPostIds = prevPosts.map((post) => post._id);
        const uniqueNewPosts = newPostsRef.current.filter(
          (post) => !existingPostIds.includes(post._id),
        );
        // console.log('resetting new posts.');
        setTimeout(() => {
          newPostsRef.current = [];
        }, 0);

        return [...uniqueNewPosts, ...prevPosts].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );
      });
    }
  };

  const NotificationContainer = () => {
    const { status } = useUpdateCheck({
      type: 'mount',
    });

    if (status === 'checking' || status === 'current') {
      return null;
    }

    const handleRefreshClick = () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          registrations.forEach((registration) => {
            registration
              .unregister()
              .then((booleanFlag) => {
                if (booleanFlag) {
                  console.log('Unregistration successful');
                } else {
                  console.log('Unregistration failed');
                }
                if (navigator.serviceWorker.controller) {
                  navigator.serviceWorker.controller.postMessage({
                    type: 'SKIP_WAITING',
                  });
                }
                window.location.reload(true); // Force reload from server, not cache
              })
              .catch((error) => {
                console.error('Service worker unregister failed:', error);
              });
          });
        });
      } else {
        window.location.reload(true); // Force reload from server, not cache
      }
    };
    // console.log('playerId', playerId);
    return (
      <div className="centerContentFeed">
        <button className="refreshToUpdateButton" type="button">
          <div className="refreshToUpdateMessage">
            A new version of Joe Galaxy is available!
          </div>
          <button
            // className="refreshToUpdateButton"
            type="button"
            onClick={handleRefreshClick}
          >
            Refresh
          </button>
        </button>
      </div>
    );
  };

  // Use the custom hook
  // useInfiniteScroll(() => {
  //   // console.log('useInfiniteScroll');
  //   setPage((oldPage) => oldPage + 1);
  //   // console.log(page);
  //   handleScroll();
  // });

  // Call handleScroll when the user scrolls up
  useInfiniteScroll(() => {
    handleScroll();
  }, 'up');

  // Call setPage when the user scrolls down
  useInfiniteScroll(() => {
    setPage((oldPage) => oldPage + 1);
  }, 'down');

  const handleDeletePost = (postId) => {
    setPostItems((prevPostItems) =>
      prevPostItems.filter((post) => post._id !== postId),
    );
  };

  const postComponents = postItems?.slice(0, page * 2).map((p, i) => {
    return (
      <Post
        galaxy={galaxy}
        initialPost={p}
        key={p._id + i}
        onDelete={handleDeletePost}
      />
    );
  });

  // console.log(currPlayer?.jgkTokens);
  return (
    <div className="feed">
      <div className="feedWrapperFeed">
        <InstallButton />
        <NotificationContainer />
        {width <= 979 && (
          <>
            {parent !== 'galaxy' && (
              <>
                <div className="feedFriendCount">
                  {friends && friends.length === 0
                    ? ''
                    : friends.length === 1
                    ? '1 Friend'
                    : `${friends.length} Friends`}
                </div>
                <div className="centerWrapper">
                  <div
                    className="feedFriendImgs"
                    // style={{
                    //   display: 'flex',
                    //   flexWrap: 'wrap',
                    //   justifyContent: 'center',
                    //   padding: '0 10px',
                    // }}
                  >
                    {friends &&
                      friends
                        .sort((a, b) => b.jgkTokens - a.jgkTokens)
                        .map((friend, i) => (
                          <Link
                            to={`/profile/${friend.playerId}`}
                            key={i}
                            style={{
                              zIndex: `${i}`,
                              margin: '1px',
                            }}
                          >
                            <img
                              src={
                                friend.profilePhoto
                                  ? friend.profilePhoto
                                  : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                              }
                              alt=""
                            />
                          </Link>
                        ))}
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <div className="friendshipFeed">
          {width <= 979 &&
            playerId !== player.data.player.playerId &&
            visitedPlayer && <Friendship player1={visitedPlayer} />}
        </div>
        {parent !== 'galaxy' && (
          <div className="feedFriendImgs">
            {myOnlineFriends &&
              myOnlineFriends.slice(0, 6).map((myOnlineFriend, i) => (
                <Link to={`/profile/${myOnlineFriend.playerId}`} key={i}>
                  <div className="feedChatOnlineImgContainer">
                    <img
                      src={
                        myOnlineFriend.profilePhoto
                          ? myOnlineFriend.profilePhoto
                          : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                      }
                      alt=""
                      style={{
                        transform: `translateX(${-i * 6}px)`,
                        zIndex: `${i}`,
                      }}
                    />
                    <div
                      className={
                        !mobileView
                          ? 'feedChatOnlineBadge'
                          : 'feedChatOnlineBadgeMobile'
                      }
                    ></div>
                  </div>
                </Link>
              ))}
          </div>
        )}
        {myOnlineFriends &&
          arrivalMessage &&
          myOnlineFriendThatMessaged &&
          parent !== 'galaxy' && (
            <>
              {(isAudio || isVideo) && (
                <div className="audioDiv">
                  <ReactPlayer
                    autoPlay={false}
                    loop={false}
                    muted={false}
                    width="72%"
                    height="75%"
                    config={{
                      file: {
                        attributes: {
                          onContextMenu: (e) => e.preventDefault(),
                        },
                      },
                    }}
                    playing={true}
                    controls={true}
                    url={arrivalMessage.img.replace('webm', 'mp4')}
                    playsinline={true}
                  />
                </div>
              )}
              {isImage && (
                <img
                  className="messageImg2 loadingImg"
                  src={arrivalMessage?.img}
                  alt=""
                  loading="lazy"
                  style={{
                    backgroundImage: `url("https://res.cloudinary.com/joegalaxy/image/upload/v1672717524/loading-gif_sfv2dl.gif")`,
                  }}
                />
              )}
              <div className="feedFriendImgs2">
                <Link
                  to={
                    myOnlineFriendThatMessaged[0] !== undefined
                      ? `/profile/${myOnlineFriendThatMessaged[0]?.playerId}`
                      : '/'
                  }
                >
                  <div className="feedChatOnlineImgContainer">
                    <img
                      className="messageImg messageImgTransform"
                      src={
                        myOnlineFriendThatMessaged[0]?.profilePhoto
                          ? myOnlineFriendThatMessaged[0]?.profilePhoto
                          : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                      }
                      alt=""
                    />
                    {onlinePlayers?.includes(
                      myOnlineFriendThatMessaged[0]?._id,
                    ) && (
                      <div
                        className={
                          !mobileView
                            ? 'feedChatOnlineBadgeMsg  messageImgTransform'
                            : 'feedChatOnlineBadgeMobileMsg messageImgTransform'
                        }
                      ></div>
                    )}
                  </div>
                </Link>

                {arrivalMessage?.text && (
                  <div className="feedOnlineFriendMsg">
                    <DynamicHtml
                      from="feed"
                      text={arrivalMessage.text}
                      setMessageTextIsVideo={setMessageTextIsVideo}
                      receiverProfilePhoto={
                        myOnlineFriendThatMessaged[0]?.profilePhoto
                      }
                    />
                  </div>
                )}
                <div
                  className={
                    !mobileView ? 'messageBottom' : 'messageBottomMobile'
                  }
                >
                  {format(arrivalMessage.createdAt)}
                </div>
                <div className="feedChatOnlineImgContainer">
                  <div className="mmenu_main hover3">
                    <Chat
                      fontSize={'medium'}
                      onClick={() => getConversationGoToMessenger()}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </>
          )}

        {parent !== 'galaxy' &&
          (mobileView || width === 820 || width === 768 || width === 912) && (
            <>
              <div>
                <h4 className="LeaderBoardTitle">JGK$ Top 10 Leader Board</h4>
              </div>

              <JGKLeaderBoard />
            </>
          )}
        {/* <div className="spotifyIframe">
          <SpotifyIframeNormalBlack option={'compact'} />
        </div> */}

        {parent !== 'galaxy' && <BTCTracker />}

        {currPlayer?.verified === false && (
          <SendVerification
            player={currPlayer !== null ? currPlayer : player.data.player}
          />
        )}
        {parent !== 'galaxy' && (
          <>
            {/*
<div
  className={
    currPlayer?.jgkTokens < 1000
      ? null
      : currPlayer?.jgkTokens > 4999
      ? 'feedLilDImgs'
      : 'feedChirbichawImgs'
  }
>
  {currPlayer?.jgkTokens > 999 && (
    <img
      className={
        currPlayer?.jgkTokens > 4999
          ? 'lilDImgFeed'
          : 'chirbichawImgTopbar'
      }
      src={
        currPlayer?.jgkTokens > 9999
          ? 'https://res.cloudinary.com/joegalaxy/image/upload/v1690738859/logo_transparency2_sdvx5i.png'
          : currPlayer?.jgkTokens > 4999 &&
            currPlayer?.jgkTokens < 10000
          ? 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1683396920/logo_transparency4_nyioc3.png'
          : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1680380660/imageedit_0_3957215681_osoxqy.png'
      }
      // width="100"
      // height="100"
      alt="Chirbichaw"
      title="Chirbichaw"
      loading="eager"
    />
  )}
  {currPlayer?.jgkTokens < 1000 && (
    <img
      className={'btc_ImgFeed'}
      src={
        'https://bitcoin.org/img/icons/opengraph.png?1660986466'
      }
      // width="100"
      // height="100"
      alt="Chirbichaw"
      title="Chirbichaw"
      loading="eager"
    />
  )}
</div>
*/}
            {currPlayer?.jgkTokens < 1000 && mobileView ? (
              <AdRotator />
            ) : (
              randomNumTwo === 1 && mobileView && <AdRotator />
            )}
          </>
        )}
        {(!playerId || parent === 'galaxy') && (
          <button
            className="postButtonRank"
            onClick={() => {
              {
                setShowForm(!showForm);
              }
            }}
          >
            {showForm ? 'Hide' : 'Create Post'}
          </button>
        )}
        {parent === 'galaxy' && showForm && (
          <Share
            parent={parent}
            galaxyId={galaxy?._id}
            setNewPost={setNewPost}
            isGalaxyMember={galaxy?.members.includes(player.data.player._id)}
            subscriptionStatus={subscriptionStatus}
          />
        )}
        {parent === null && playerId === undefined && showForm && (
          <Share
            parent={parent}
            galaxyId={galaxy?._id}
            setNewPost={setNewPost}
            isGalaxyMember={galaxy?.members.includes(player.data.player._id)}
            subscriptionStatus={subscriptionStatus}
          />
        )}
        {/* Memory Post */}
        {parent !== 'galaxy' && randomNumTwo === 0
          ? memoryPosts[randomNum] !== undefined && <></>
          : !mobileView && parent !== 'galaxy' && randomNumTwo === 1 && <></>}
        {isLoading ? (
          <div className="feedPostProgress">
            <CircularProgress color="inherit" size="10px" />
          </div>
        ) : parent !== 'galaxy' ? (
          <MemoryPostRotator />
        ) : (
          parent !== 'galaxy' &&
          randomNumTwo === 1 && (
            <>
              <div>
                <h4 className="postTitleThrowback">Meme Machine</h4>
              </div>
              <MemeRotator />
            </>
          )
        )}

        <div
          className={!mobileView ? 'memePostToggle' : 'memePostToggleMobile'}
        >
          {/* <button
            className="postLeftButtonRank"
            onClick={sortBy === 0 ? handleSortTopRanked : handleSortNewest}
            id="sort"
          >
            {sortBy === 0 ? 'Top Ranked' : 'Newest'}
          </button> */}

          {parent !== 'galaxy' && mobileView && (
            <button
              className="postButtonRank"
              onClick={sortBy === 0 ? handleSortTopRanked : handleSortNewest}
              id="sort"
            >
              <Link
                to="/meme"
                style={{ textDecoration: 'none' }}
                className="memeLinkItem"
              >
                Meme Machine
              </Link>
            </button>
          )}
        </div>
        <h4 className="postTitle">
          {sortBy === 0 ? 'Newest' : 'Top Rank'} Posts
        </h4>
        {isLoading ? (
          <div className="feedPostProgress">
            <CircularProgress color="inherit" size="10px" />
          </div>
        ) : (
          ((parent !== 'galaxy' &&
            (isFriend ||
              !playerId ||
              playerId === player.data.player.playerId)) ||
            (parent === 'galaxy' && isGalaxyMember) ||
            subscriptionStatus === 'Subscribed') &&
          postComponents // Added condition
        )}
      </div>
    </div>
  );
}
