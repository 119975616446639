// import '../topbar.css';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Search,
  Person,
  Chat,
  // Notifications
} from '@material-ui/icons';

import LogoutIcon from '@mui/icons-material/Logout';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';

import { Tooltip } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import Logoff from '../../../utils/logoffTopbar';
import isExcluded from '../../../utils/isExcluded';

export default function PlayerMenu({ player, parent }) {
  // console.log(parent);

  const [visible, setVisible] = useState(0);
  //console.log(player);
  // const Logoff = () => {
  //   localStorage.clear();
  //   window.location.href = '/login';
  // };
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  // console.log(parent);
  return (
    <div className={parent === 'JGKToken' ? 'mmenuJGKToken' : 'mmenu'}>
      {visible === 0 && (
        <div>
          <Link
            to={`/profile/${player?.playerId}`}
            className="mmenu_header hover3"
          >
            <img
              src={
                player?.profilePhoto
                  ? player?.profilePhoto
                  : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
              }
              // src={player?.profilePhoto}
              alt=""
            />
            <div className="mmenu_col">
              <span>{player?.playerId}</span>
              <span>See your profile</span>
            </div>
          </Link>
          {!isExcluded('Galaxies') && (
            <>
              <div className="mmenu_splitter"></div>
              <Link to={`/galaxies/`} className="mmenu_header hover3">
                <div className="mmenu_main hover3">
                  <div className="small_circle">
                    {/* <i className="ad_choices_icon"></i> */}
                    <Diversity3OutlinedIcon fontSize={'small'} />
                  </div>
                  <div className="mmenu_col">
                    {/* <div className="mmenu_span1">Give feedback</div> */}

                    <div className="mmenu_span2">Galaxies</div>
                  </div>
                </div>
              </Link>
            </>
          )}
          <div className="mmenu_splitter"></div>
          <Link to={`/jgktoken/`} className="mmenu_header hover3">
            <div className="mmenu_main hover3">
              <div className="small_circle">
                {/* <i className="ad_choices_icon"></i> */}
                <CurrencyExchangeOutlinedIcon fontSize={'small'} />
              </div>
              <div className="mmenu_col">
                {/* <div className="mmenu_span1">Give feedback</div> */}

                <div className="mmenu_span2">JGK$ & Gift Cards 🎁</div>
              </div>
            </div>
          </Link>
          <div className="mmenu_splitter"></div>
          <Link to={`/messenger/''`} className="mmenu_header hover3">
            <div className="mmenu_main hover3">
              <div className="small_circle">
                {/* <i className="ad_choices_icon"></i> */}
                <Chat fontSize={'small'} />
              </div>
              <div className="mmenu_col">
                {/* <div className="mmenu_span1">Give feedback</div> */}

                <div className="mmenu_span2">Chat </div>
              </div>
            </div>
          </Link>

          <div className="mmenu_splitter"></div>
          <Link to={'/'} className="mmenu_header hover3">
            <div
              className="mmenu_main hover3"
              onClick={() => {
                Logoff();
              }}
            >
              <div className="small_circle">
                {/* <i className="ad_choices_icon"></i> */}
                <LogoutIcon
                  // className="white-color"
                  onClick={Logoff}
                  fontSize={'small'}
                />
              </div>
              <div className="mmenu_col">
                <div className="mmenu_span2">Logout </div>
              </div>
            </div>
          </Link>

          {!isExcluded('PlayerMap') && (
            <>
              <div className="mmenu_splitter"></div>
              <Link to={`/playermap`} className="mmenu_header hover3">
                <div className="mmenu_main hover3">
                  <div className="small_circle">
                    {/* <i className="ad_choices_icon"></i> */}
                    <PersonPinIcon fontSize="x-small" />
                  </div>
                  <div className="mmenu_col">
                    {/* <div className="mmenu_span1">Give feedback</div> */}

                    <div className="mmenu_span2">Player Map </div>
                  </div>
                </div>
              </Link>
            </>
          )}
          {!isExcluded('Memes') && (
            <>
              <div className="mmenu_splitter"></div>
              <Link to={`/meme`} className="mmenu_header hover3">
                <div className="mmenu_main hover3">
                  <div className="small_circle">
                    {/* <i className="ad_choices_icon"></i> */}
                    <QueuePlayNextOutlinedIcon fontSize="x-small" />
                  </div>
                  <div className="mmenu_col">
                    {/* <div className="mmenu_span1">Give feedback</div> */}

                    <div className="mmenu_span2">Meme Machine </div>
                  </div>
                </div>
              </Link>
            </>
          )}
          {!isExcluded('Ads') && (
            <>
              <div className="mmenu_splitter"></div>
              <Link to={`/advertise`} className="mmenu_header hover3">
                <div className="mmenu_main hover3">
                  <div className="small_circle">
                    {/* <i className="ad_choices_icon"></i> */}
                    <SellOutlinedIcon fontSize="medium" />
                  </div>
                  <div className="mmenu_col">
                    {/* <div className="mmenu_span1">Give feedback</div> */}

                    <div className="mmenu_span2">Advertise </div>
                  </div>
                </div>
              </Link>
            </>
          )}

          <div className="mmenu_splitter"></div>
          <Link
            to={{
              pathname: `https://webmail.joegalaxy.net/login/`,
              search: `?user=${player.playerId}@joegalaxy.net`,
            }}
            target="_blank"
            className="mmenu_header hover3"
          >
            <div className="mmenu_main hover3">
              <div className="small_circle">
                <EmailOutlinedIcon fontSize={'medium'} />
              </div>
              <div className="mmenu_col">
                <span>Unlimited JGEmails:</span>
                <span className="mmenu_emailAddressTxt">
                  {player.playerId}@joegalaxy.net
                </span>
              </div>
            </div>
          </Link>

          <div className="mmenu_splitter"></div>
          <Link to={`/feedback`} className="mmenu_header hover3">
            {' '}
            <div className="small_circle">
              <i className="report_filled_icon"></i>
            </div>
            <div className="mmenu_col">
              {/* <div className="mmenu_span1">Give feedback</div> */}
              <div className="mmenu_span2">
                Help us improve www.JoeGalaxy.NET
              </div>
            </div>
          </Link>
          {/* <div className="mmenu_splitter"></div> */}
          {/* <div
            className="mmenu_item hover3"
            onClick={() => {
              setVisible(1);
            }}
          >
            <div className="small_circle">
              <i className="settings_filled_icon"></i>
            </div>
            <span>Settings & privacy</span>
            <div className="rArrow">
              <i className="right_icon"></i>
            </div>
          </div>
          <div
            className="mmenu_item hover3"
            onClick={() => {
              setVisible(2);
            }}
          >
            <div className="small_circle">
              <i className="help_filled_icon"></i>
            </div>
            <span>Help & support</span>
            <div className="rArrow">
              <i className="right_icon"></i>
            </div>
          </div>
          <div
            className="mmenu_item hover3"
            onClick={() => {
              setVisible(3);
            }}
          >
            <div className="small_circle">
              <i className="dark_filled_icon"></i>
            </div>
            <span>Display & Accessibility</span>
            <div className="rArrow">
              <i className="right_icon"></i>
            </div>
          </div> */}
          {/* <div
            className="mmenu_item hover3"
            onClick={() => {
              Logoff();
            }}
          >
            <div className="small_circle">
              <i className="logout_filled_icon"></i>
            </div>
            <span>Logout</span>
          </div> */}
        </div>
      )}
      {/* {visible === 1 && <SettingsPrivacy setVisible={setVisible} />}
      {visible === 2 && <HelpSupport setVisible={setVisible} />}
      {visible === 3 && <DisplayAccessibility setVisible={setVisible} />} */}
    </div>
  );
}
